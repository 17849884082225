import React from 'react'
import ReactDOM from 'react-dom'

const Dialog = ({ isOpen, onClose, children ,height='h-[720px]', width='w-[1280px]'}) => {
    console.log("open",isOpen)
    console.log("onClose",onClose)
   
    return ReactDOM.createPortal(
      <div className={` ${isOpen ? 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50':''}`}>
        <div  className={`fixed top-0 right-0 h-full z-50 transform ${
        isOpen ? 'translate-x-0 opacity-100 transition-transform duration-700' : 'translate-x-full opacity-100 transition-transform duration-700'
      }  ease-in-out overflow-y-scroll `}>
        <div className={`bg-white ${width} ${height} rounded-md shadow-md `}>
          {children}
        </div>
      </div>
      </div>,
      document.body
    );
}

export default Dialog