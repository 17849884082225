import React,{useState} from "react";
import Layout from "../../../Layout";
import Tabs from "../../../../components/Tabs";
import { Link } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import VendorTransactionList from "../vendor-transaction-list/VendorTransactionList";
import VendorDetails from "../vendor-details/VendorDetails";
import Modal from '../../../../components/Modal';
import { useTranslation } from "react-i18next";

const VendorDashboard = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      id: "transactionlist",
      title: "Transaction List",
      content: <VendorTransactionList />,
    },
    {
      id: "vendordetails",
      title: "Vendor Details",
      content: <VendorDetails />,
    },
  ];
  const [isInactiveModalOpen, setInactiveModalOpen] = useState(false);
  const [isMakeInactiveModalOpen, setMakeInactiveModalOpen] = useState(false);

  const openMakeInactiveModal = () => setMakeInactiveModalOpen(true);
  const closeMakeInactiveModal = () => setMakeInactiveModalOpen(false);
  const openInactiveModal = () => setInactiveModalOpen(true);
  const closeInactiveModal = () => setInactiveModalOpen(false);
    const makeInactive = () => {
      
        openMakeInactiveModal();
     
      };
    
      
    const inactiveWithOpenBalance = () => {
      
        openInactiveModal();
     
      };
  return (
    <>
      <Layout>
        <div className="bg-white w-full h-full px-8 my-1 overflow-y-scroll">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold font-sans py-7">
                <h1>Vendors Dashboard</h1>
              </div>
              <div className="flex content-center">
                <IoChevronBackOutline className="text-blueRibbon mt-1 text-lg cursor-pointer" />
                <Link
                  to="/customer"
                  className="mb-2 text-lg font-semibold font-sans text-blueRibbon ml-2"
                >
                  Vendor
                </Link>
              </div>
              <div className="w-full h-auto flex gap-2">
                <div className="w-[75%] bg-bonJour px-4 h-full">
                <div className="flex justify-between pt-2">
                    <p className="text-2xl font-sans font-semibold pb-3">
                      Sample Vendor Name
                    </p>
                    <button className="flex justify-end pr-4 pt-2 text-lochmara text-base font-sans font-semibold" onClick={makeInactive}>Make it inactive</button>
                    </div>
                  <div className="w-[70%] py-2">
                    
                    <div className="flex justify-between">
                      <div>
                        <p className="font-normal text-sm font-sans">Company</p>
                        <p className="font-semibold text-sm font-sans">
                          Sample Vendor Inc
                        </p>
                      </div>
                      <div>
                        <p className="font-normal text-sm font-sans">
                          Billing address
                        </p>
                        <div className="font-semibold text-sm font-sans">
                          <p>123 some street</p>
                          <p>Suite # 999</p>
                          <p>Some City, Some State</p>
                          <p>Some Country, 999 9999</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-1">
                    <lable className="font-normal text-sm font-sans">
                      Notes
                    </lable>
                    <div className="font-semibold text-sm font-sans py-1">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Esse nesciunt cupiditate nobis deserunt ea non
                        illum inventore explicabo dolores, ad eius corporis
                        nisi. Incidunt, inventore esse recusandae ipsum eveniet
                        voluptatibus?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[25%]">
                  
                  <div className="w-full h-[49%] bg-lightblue mb-[5px] content-center px-7" onClick={inactiveWithOpenBalance}>
                    <div className="text-white text- font-sans font-semibold">
                      <p className="text-2xl" >$30.00</p>
                      <p className="text-lg">Open balance</p>
                    </div>
                  </div>
                 
                  <div className="w-full h-[49%] bg-malachite content-center px-7">
                  <div className="text-white  font-sans font-semibold">
                      <p className="text-2xl">$0.00</p>
                      <p className="text-lg">Overdue payment</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Tabs tabs={tabs} />
          </div>
        </div>
      </Layout>
      <Modal isOpen={isMakeInactiveModalOpen}>
        <div className="relative">
          <button
            onClick={closeMakeInactiveModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-6 h-6 mx-5 my-2" />
          </button>
          <img src="warning-icon.png" className="w-9 h-9 mx-auto mb-3" />
        </div>
        <p className="text-sm font-normal my-5 mt-9 px-7 text-start">
        Test has an open balance. Making this vendor inactive will cause an adjusting transaction to be created.
        </p>
        <div className="px-7"><p className="text-sm font-normal text-start">Is this OK?</p></div>
        <button
          onClick={closeMakeInactiveModal}
          className="my-5 uppercase text-white bg-lochmara font-bold text-base w-[200px] h-[36px] rounded-[10px]"
        >
          YES, MAKE INACTIVE
        </button>
      </Modal>
      <Modal isOpen={isInactiveModalOpen} width="w-[700px]" height="h-[400px]">
        <div className="relative">
          <button
            onClick={closeInactiveModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-6 h-6 mx-5 my-2" />
          </button>
          <img src="warning-icon.png" className="w-[56px] h-[57px] mx-auto mb-20 my-3" />
        </div>
        <p className="text-2xl font-sans font-normal my-16 mt-10 px-14 text-start">
        Are you sure you want to make Pradeep Kumar inactive?
        </p>
        <button
          onClick={closeInactiveModal}
          className="my-5 uppercase text-white bg-pearl mr-8 font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
        >
          {t("cancel")}
        </button>
        <button
          onClick={closeInactiveModal}
          className="my-5 uppercase text-white bg-lochmara font-bold text-sm w-[200px] h-[36px] rounded-[10px]"
        >
          YES, MAKE INACTIVE
        </button>
      </Modal>
    </>
  );
};

export default VendorDashboard;
