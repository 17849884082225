import React, { useRef ,useState} from "react";
import { useTranslation } from "react-i18next";
import validation from "../../../utils/validation";
import { validate } from "../../../utils/formValidation";
import Tooltip from "../../../utils/toolTip";

const AddEditCustomer = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({title:"",fname: "",mname: "",lname: "",suffix:"",companyName:"",
    email:"",phoneNumber:"",mobileNumber:"",fax:"",website:"",checkPayableTo:"",streetAddress1:"",streetAddress2:"",
    city:"",state:"",zipCode:"",country:"",sameAsBillingAddress:false,shippingStreetAddress1:"",shippingStreetAddress2:"",
    shippingCity:"",shippingState:"",shippingCountry:"",shippingZipCode:"",notes: "",attachemntFile: null,
  });
  const [errors, setErrors] = useState({title:"",fname: "",mname: "",lname: "",suffix:"",companyName:"",
    email:"",phoneNumber:"",mobileNumber:"",fax:"",website:"",checkPayableTo:"",streetAddress1:"",streetAddress2:"",
    city:"",state:"",zipCode:"",country:"",sameAsBillingAddress:false,shippingStreetAddress1:"",shippingStreetAddress2:"",
    shippingCity:"",shippingState:"",shippingCountry:"",shippingZipCode:"",notes: "",attachemntFile: "",
  });
  const [file, setFile] = useState(null);
  //const [error, setError] = useState('');
  const [validateFile,setValidateFile] = useState("");
 
  const validationErrors = {};

  const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  const maxSize = 2 * 1024 * 1024; // 2 MB
  

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log("e.target ",e.target.type)
  //   let selectedFile = "";
  //   if(e.target.files){
  //      selectedFile = e.target.files[0];
  //   }
  //   console.log("file",selectedFile)
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  //   console.log(formData);
  //   if (formData.fname !== "") {
  //     if (!formData.fname.trim()) {
  //       validationErrors.fname = t("providefirstname");
  //     } else if (!validation.firstName.pattern.value.test(formData.fname)) {
  //       validationErrors.fname = t("firstletteralpha");
  //     }else if (!validation.alphaNumeric.pattern.value.test(formData.fname)) {
  //       validationErrors.fname = t("alphanumeric");
  //     }

  //   }
  //   if (formData.lname !== "") {
  //     if (!formData.lname.trim()) {
  //       validationErrors.lname = t("providelastname");
  //     } else if (!validation.firstName.pattern.value.test(formData.lname)) {
  //       validationErrors.lname = t("firstletteralpha");
  //     }
  //   }
  //   if (formData.email !== "") {
  //     if (!formData.email.trim()) {
  //       validationErrors.email = t("provideemail");
  //     } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
  //       validationErrors.email = t("invalidemail");
  //     }
  //   }
  //   if (formData.mobileNumber !== "") {
  //     if (!formData.mobileNumber.trim()) {
  //       validationErrors.mobileNumber = t("providemobileno");
  //     } else if (!validation.number.pattern.value.test(formData.mobileNumber)) {
  //       validationErrors.mobileNumber = t("invalidmobileno");
  //     }
  //   }
  //   if (formData.phoneNumber !== "") {
  //     if (!formData.phoneNumber.trim()) {
  //       validationErrors.phoneNumber = t("providemobileno");
  //     } else if (!validation.number.pattern.value.test(formData.phoneNumber)) {
  //       validationErrors.phoneNumber = t("invalidmobileno");
  //     }
  //   }
  //   if (formData.fax !== "") {
  //     if (!formData.fax.trim()) {
  //       validationErrors.fax = t("providemobileno");
  //     } else if (!validation.number.pattern.value.test(formData.fax)) {
  //       validationErrors.fax = t("invalidmobileno");
  //     }
  //   }
  //   if (formData.website !== "") {
  //     if (!formData.website.trim()) {
  //       validationErrors.website = t("providemobileno");
  //     } else if (!validation.website.pattern.value.test(formData.website)) {
  //       validationErrors.website = t("invalidmobileno");
  //     }
  //   }
  //   if (formData.checkPayableTo !== "") {
  //     if (!formData.checkPayableTo.trim()) {
  //       validationErrors.checkPayableTo = t("providemobileno");
  //     } else if (!validation.alphaNumeric.pattern.value.test(formData.checkPayableTo)) {
  //       validationErrors.checkPayableTo = t("alphanumeric");
  //     }
  //   }
  //   if (formData.streetAddress1 !== "") {
  //     if (!formData.streetAddress1.trim()) {
  //       validationErrors.streetAddress1 = t("providemobileno");
  //     } else if (!validation.address.pattern.value.test(formData.streetAddress1)) {
  //       validationErrors.streetAddress1 = t("alphanumeric");
  //     }
  //   }
  //   if (formData.streetAddress2 !== "") {
  //     if (!formData.streetAddress2.trim()) {
  //       validationErrors.streetAddress2 = t("providemobileno");
  //     } else if (!validation.address.pattern.value.test(formData.streetAddress2)) {
  //       validationErrors.streetAddress2 = t("alphanumeric");
  //     }
  //   }
  //   if (formData.city !== "") {
  //     if (!formData.city.trim()) {
  //       validationErrors.city = t("providemobileno");
  //     } else if (!validation.address.pattern.value.test(formData.city)) {
  //       validationErrors.city = t("alphanumeric");
  //     }
  //   }
  //   if (formData.state !== "") {
  //     if (!formData.state.trim()) {
  //       validationErrors.state = t("providemobileno");
  //     } else if (!validation.address.pattern.value.test(formData.state)) {
  //       validationErrors.state = t("alphanumeric");
  //     }
  //   }
  //   if (formData.zipCode !== "") {
  //     if (!formData.zipCode.trim()) {
  //       validationErrors.zipCode = t("providemobileno");
  //     } else if (!validation.zipcode.pattern.value.test(formData.zipCode)) {
  //       validationErrors.zipCode = t("alphanumeric");
  //     }
  //   }
  //   if (formData.shippingStreetAddress1 !== "") {
  //     if (!formData.shippingStreetAddress1.trim()) {
  //       validationErrors.shippingStreetAddress1 = t("providemobileno");
  //     } else if (!validation.address.pattern.value.test(formData.shippingStreetAddress1)) {
  //       validationErrors.shippingStreetAddress1 = t("alphanumeric");
  //     }
  //   }
  //   if (formData.shippingStreetAddress2 !== "") {
  //     if (!formData.shippingStreetAddress2.trim()) {
  //       validationErrors.shippingStreetAddress2 = t("providemobileno");
  //     } else if (!validation.address.pattern.value.test(formData.shippingStreetAddress2)) {
  //       validationErrors.shippingStreetAddress2 = t("alphanumeric");
  //     }
  //   }
  //   if (formData.shippingCity !== "") {
  //     if (!formData.shippingCity.trim()) {
  //       validationErrors.shippingCity = t("providemobileno");
  //     } else if (!validation.address.pattern.value.test(formData.shippingCity)) {
  //       validationErrors.shippingCity = t("alphanumeric");
  //     }
  //   }
  //   if (formData.shippingState !== "") {
  //     if (!formData.shippingState.trim()) {
  //       validationErrors.shippingState = t("providemobileno");
  //     } else if (!validation.address.pattern.value.test(formData.shippingState)) {
  //       validationErrors.shippingState = t("alphanumeric");
  //     }
  //   }
  //   if (formData.shippingZipCode !== "") {
  //     if (!formData.shippingZipCode.trim()) {
  //       validationErrors.shippingZipCode = t("providemobileno");
  //     } else if (!validation.zipcode.pattern.value.test(formData.shippingZipCode)) {
  //       validationErrors.shippingZipCode = t("alphanumeric");
  //     }
  //   }
  //   if (formData.notes !== "") {
  //     if (!formData.notes.trim()) {
  //       validationErrors.notes = t("providemobileno");
  //     } else if (!validation.address.pattern.value.test(formData.notes)) {
  //       validationErrors.notes = t("alphanumeric");
  //     }
  //   }
  //   console.log("type : ",selectedFile.type," allowed size : ",maxSize, " size : " , selectedFile.size)
  //   if (selectedFile) {
  //       if (!allowedTypes.includes(selectedFile.type)) {
  //          validationErrors.attachemntFile =  "Invalid file type. Only JPEG, PNG, and PDF are allowed."
  //       } else if (selectedFile.size > maxSize) {
  //         validationErrors.attachemntFile = "File size exceeds 2 MB."
  //       }
  //   }
  //   setErrors(validationErrors);
  //   console.log(errors);
  // };
    
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   onSubmit();
  // };

  // const handleClose = (e) => {
  //   e.preventDefault();
  //   onClose();
  // };

  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click(); // Trigger file input click
  };

  // const handleFileChange = (e) => {
  //   //const file = e.target.files[0];
  //   const selectedFile = e.target.files[0];
  //   console.log("selected file : ",selectedFile);
    
  //   console.log("type : ",selectedFile.type," allowed size : ",maxSize, " size : " , selectedFile.size)
  //   if (selectedFile) {
  //       if (!allowedTypes.includes(selectedFile.type)) {
  //         alert("Invalid file type. Only JPEG, PNG, and PDF are allowed")
  //          validationErrors.attachemntFile =  "Invalid file type. Only JPEG, PNG, and PDF are allowed."
  //           setErrors(validationErrors);
  //           setFile(null);
  //           return;
  //       } 

  //       if (selectedFile.size > maxSize) {
  //         alert("File size exceeds 2 MB.")
  //         validationErrors.attachemntFile = "File size exceeds 2 MB."
  //           setErrors(validationErrors);
  //           console.log(validationErrors)
  //           setFile(null);
  //           return;
  //       }

  //       setFile(selectedFile);
  //       setErrors('');
  //       console.log(validationErrors.attachemntFile)
  //   }
  // };

  //-----------------separated validation file---------------------------------------------------//
  //const [formData, setFormData] = useState({ date:'',paymentMethod:'' ,refno:'',depositTo:''});
  //const [errors, setErrors] = useState({ date:'' ,paymentMethod:'',refno:'',depositTo:''});

  // Handle change in input fields
  const handleChange = (event) => {
      const { name, value } = event.target;
      let selectedFile = "";
      if(event.target.files){
        selectedFile = event.target.files[0];
      }

      // Update form data state
      setFormData(prevData => ({
          ...prevData,
          [name]: value
      }));

      // Validate field
      const error = validate(name, value);
      setErrors(prevErrors => ({
          ...prevErrors,
          [name]: error
      }));
    //   let attachemntFileErrors = "";
    //   if (selectedFile) {
    //     if (!allowedTypes.includes(selectedFile.type)) {
    //       attachemntFileErrors =  "Invalid file type. Only JPEG, PNG, and PDF are allowed."
    //    } else if (selectedFile.size > maxSize) {
    //     attachemntFileErrors = "File size exceeds 2 MB."
    //    }      
    //   }
    //   console.log("file error :",attachemntFileErrors)
    //   setValidateFile(attachemntFileErrors);
    // console.log("file error :",validateFile)

   

  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate all fields
    const newErrors = {
        fname: validate('fname', formData.fname),
        lname: validate('lname', formData.lname),
        email: validate('email', formData.email),
        mobileNumber: validate('mobileNumber', formData.mobileNumber)
    };
    setErrors(newErrors);
   

    // Check if there are any errors
    if (!newErrors.date && !newErrors.paymentMethod && !newErrors.depositTo && !newErrors.refno) {
        // Form is valid, handle submission
        console.log('Form submitted:', formData);
    }
    const formErrors = [
      ...validate(formData.file),
    ];
    setErrors(formErrors);
  console.log(errors)
  };

  const handleClose = ()=>{
    onClose();
    setFormData({title:"",fname: "",mname: "",lname: "",suffix:"",companyName:"",
      email:"",phoneNumber:"",mobileNumber:"",fax:"",website:"",checkPayableTo:"",streetAddress1:"",streetAddress2:"",
      city:"",state:"",zipCode:"",country:"",sameAsBillingAddress:false,shippingStreetAddress1:"",shippingStreetAddress2:"",
      shippingCity:"",shippingState:"",shippingCountry:"",shippingZipCode:"",notes: "",attachemntFile: null,
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFormData({
      ...formData,
      attachemntFile: selectedFile,
    });

    
  };

  return (
    <>
      <div className="bg-white h-auto w-[700px] font-sans p-5">
        <form onSubmit={handleSubmit}>
          <h1 className="text-center font-semibold text-2xl">Customer</h1>
          {/* <div className="border border-x-bombay px-[14px] pt-1 my-2 py-2 w-[650px] h-auto shadow-[2px_3px_2px_2px_rgba(0,0,0,0.2)] shadow-tpGray"> */}
          <div className="border border-x-bombay px-[14px] pt-1 my-2 py-2 w-[650px] h-auto">
            <div className="text-lg font-semibold">
              <lable>Name and contact</lable>
            </div>
            <div className="flex justify-center">
            <div className="flex text-base font-normal justify-center gap-2.5 py-1 w-[620px]">
              <div className="w-[65px]">
                <lable>Title</lable>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  maxLength={3}
                  onChange={handleChange}
                  className={`${
                    errors.title
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[65px] h-[35px] px-2 outline-none
     `}
                />
                {errors.title && (
                  <Tooltip message={errors.title}>
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        In...
                      </span>
                    </Tooltip>
                    )}
              </div>
              <div className="w-[150px]">
                <lable>First Name *</lable>
                <input
                  type="text"
                  name="fname"
                  value={formData.fname}
                  maxLength={100}
                  onChange={handleChange}
                  className={`${
                    errors.fname
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm h-[35px] w-[150px] outline-none px-2
     `}
                />
                 {errors.fname && (
                  <Tooltip message={errors.fname}>
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        Invalid first...
                      </span>
                    </Tooltip>
                    )}
              </div>
             
              <div className="w-[150px]">
                <lable>Middle Name</lable>
                <input
                  type="text"
                  name="mname"
                  value={formData.mname}
                  maxLength={50}
                  onChange={handleChange}
                  className={`${
                    errors.mname
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm h-[35px] w-[150px] outline-none px-2
     `}
                />
                {errors.mname && (
                  <Tooltip message={errors.mname}>
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        Invalid mid...
                      </span>
                    </Tooltip>
                    )}
              </div>
              <div className="w-[150px]">
                <lable>Last Name *</lable>
                <input
                  type="text"
                  name="lname"
                  value={formData.lname}
                  maxLength={100}
                  onChange={handleChange}
                  className={`${
                    errors.lname
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm h-[35px] w-[150px] px-2 outline-none `}
                />
                {errors.lname && (
                  <Tooltip message={errors.lname}>
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        Invalid last...
                      </span>
                    </Tooltip>
                    )}
              </div>
              
              <div className="w-[65px]">
                <lable>Suffix</lable>
                <input
                  type="text"
                  name="suffix"
                  value={formData.suffix}
                  maxLength={50}
                  onChange={handleChange}
                  className="border border-bombay rounded-sm w-[65px] h-[35px] px-2 outline-none focus:border-lochmara"
                />
              </div>
            </div>
            </div>
            <div className="flex text-base justify-center gap-2 py-1 w-full">
              <div className="w-[305px]">
                <lable>Company Name</lable>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  maxLength={100}
                  onChange={handleChange}
                  className={`${
                    errors.companyName
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none
     `}
                />
                 {errors.companyName && (
                  <Tooltip message={errors.companyName}>
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        Invalid company name...
                      </span>
                    </Tooltip>
                    )}
              </div>
              <div className="w-[305px]">
                <lable>Email *</lable>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  maxLength={50}
                  onChange={handleChange}
                  className={`${
                    errors.email
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                />
                {errors.email && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.email}
                      </span>
                    )}
              </div>
            </div>
            <div className="flex text-base justify-center gap-2 py-1 w-full">
              <div className="w-[305px]">
                <lable>Phone Number</lable>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  maxLength={12}
                  onChange={handleChange}
                  className={`${
                    errors.phoneNumber
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                />
                {errors.phoneNumber && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.phoneNumber}
                      </span>
                    )}
              </div>
              <div className="w-[305px]">
                <lable>Mobile Number *</lable>
                <input
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  maxLength={12}
                  onChange={handleChange}
                  className={`${
                    errors.mobileNumber
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                />
                {errors.mobileNumber && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.mobileNumber}
                      </span>
                    )}
              </div>
            </div>
            <div className="flex text-base justify-center gap-2 py-1 w-full">
              <div className="w-[305px]">
                <lable>Fax</lable>
                <input
                  type="text"
                  name="fax"
                  value={formData.fax}
                  maxLength={12}
                  onChange={handleChange}
                  className={`${
                    errors.fax
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                />
                {errors.fax && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.fax}
                      </span>
                    )}
              </div>
              <div className="w-[305px]">
                <lable>Website</lable>
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  maxLength={50}
                  onChange={handleChange}
                  className={`${
                    errors.website
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                  //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                />
                {errors.website && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.website}
                      </span>
                    )}
              </div>
            </div>
            <div className="flex text-base justify-center gap-2 py-1 w-full">
              <div className="w-[620px]">
                <lable>Check payable to</lable>
                <input
                  type="text"
                  name="checkPayableTo"
                  value={formData.checkPayableTo}
                  maxLength={100}
                  onChange={handleChange}
                  className={`${
                    errors.checkPayableTo
                      ? "border border-errorRed focus:outline-none"
                      : "border-bombay border focus:border-lochmara focus:outline-0"
                  } rounded-sm w-[617px] h-[35px] px-1 outline-none `}
                  //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                />
                {errors.checkPayableTo && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.checkPayableTo}
                      </span>
                    )}
              </div>
            </div>
          </div>
          <div className="border border-x-bombay content-center px-[14px] py-2 w-[650px] h-auto">
            <div className="text-lg font-semibold">
              <lable>Addresses</lable>
            </div>
            <div>
              <div>
                <div className="text-base font-semibold py-1">
                  <lable>Billing address</lable>
                </div>
                <div className="flex text-base justify-center gap-2 py-1 w-full">
                  <div className="w-[305px]">
                    <lable>Street address 1</lable>
                    <input
                      type="text"
                      name="streetAddress1"
                      value={formData.streetAddress1}
                      maxLength={100}
                      onChange={handleChange}
                      className={`${
                        errors.streetAddress1
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.streetAddress1 && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.streetAddress1}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px]">
                    <lable>Street address 2</lable>
                    <input
                      type="text"
                      name="streetAddress2"
                      value={formData.streetAddress2}
                      maxLength={100}
                      onChange={handleChange}
                      className={`${
                        errors.streetAddress2
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.streetAddress2 && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.streetAddress2}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex text-base justify-center gap-2 py-1 w-full">
                  <div className="w-[305px]">
                    <lable>City</lable>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.city
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.city && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.city}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px]">
                    <lable>State</lable>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.state
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.state && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.state}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex text-base justify-center gap-2 py-1 w-full">
                  <div className="w-[305px]">
                    <lable>Zip code/Postal code</lable>
                    <input
                      type="text"
                      name="zipCode"
                      value={formData.zipCode}
                      maxLength={6}
                      onChange={handleChange}
                      className={`${
                        errors.zipCode
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.zipCode && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.zipCode}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px]">
                    <lable>Country</lable>
                    <input
                      type="text"
                      name="country"
                      value={formData.country}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.country
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.country && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.country}
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-base pt-1 flex">
                  <input type="checkbox" className="w-6 h-6" />
                  <div className="content-center">
                  <lable className="px-2">Same as billing address</lable>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-base font-semibold pt-1">
                  <lable>Shipping Address</lable>
                </div>
                <div className="flex text-base justify-center gap-2 py-1 w-full">
                  <div className="w-[305px]">
                    <lable>Street address 1</lable>
                    <input
                      type="text"
                      name="shippingStreetAddress1"
                      value={formData.shippingStreetAddress1}
                      maxLength={100}
                      onChange={handleChange}
                      className={`${
                        errors.shippingStreetAddress1
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-full h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.shippingStreetAddress1 && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.shippingStreetAddress1}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px]">
                    <lable>Street address 2</lable>
                    <input
                      type="text"
                      name="shippingStreetAddress2"
                      value={formData.shippingStreetAddress2}
                      maxLength={100}
                      onChange={handleChange}
                      className={`${
                        errors.shippingStreetAddress2
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.shippingStreetAddress2 && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.shippingStreetAddress2}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex text-base justify-center gap-2 py-1 w-full">
                  <div className="w-[305px]">
                    <lable>City</lable>
                    <input
                      type="text"
                      name="shippingCity"
                      value={formData.shippingCity}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.shippingCity
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.shippingCity && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.shippingCity}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px]">
                    <lable>State</lable>
                    <input
                      type="text"
                      name="shippingState"
                      value={formData.shippingState}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.shippingState
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.shippingState && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.shippingState}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex text-base justify-center gap-2 py-1 w-full">
                  <div className="w-[305px]">
                    <lable>Zip code/Postal code</lable>
                    <input
                      type="text"
                      name="shippingZipCode"
                      value={formData.shippingZipCode}
                      maxLength={6}
                      onChange={handleChange}
                      className={`${
                        errors.shippingZipCode
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.shippingZipCode && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.shippingZipCode}
                      </span>
                    )}
                  </div>
                  <div className="w-[305px]">
                    <lable>Country</lable>
                    <input
                      type="text"
                      name="shippingCountry"
                      value={formData.shippingCountry}
                      maxLength={50}
                      onChange={handleChange}
                      className={`${
                        errors.shippingCountry
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[305px] h-[35px] px-1 outline-none `}
                      //className="border border-bombay rounded-sm w-full h-[35px] px-1 outline-none focus:border-lochmara"
                    />
                    {errors.shippingCountry && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.shippingCountry}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-x-bombay content-center px-[14px] my-2 w-[650px] h-auto">
            <div className="text-base font-semibold pt-1">
              <lable>Notes and attachements</lable>
            </div>
            <div className="flex text-base justify-center gap-2 py-1">
              <div className="w-[620px]">
                <div className="py-2">
                  <lable>Notes</lable>
                  <div className="">
                    <textarea
                      name="notes"
                      value={formData.notes}
                      maxLength={500}
                      onChange={handleChange}
                      className={`${
                        errors.notes
                          ? "border border-errorRed focus:outline-none"
                          : "border-bombay border focus:border-lochmara focus:outline-0"
                      } rounded-sm w-[620px] h-[93px] border-2 border-tpGray p-1 outline-none resize-none `}
                      //className="w-full h-16 border-2 border-tpGray resize-none outline-none focus:border-lochmara p-1"
                    />
                    {errors.notes && (
                      <span className="text-errorRed text-base flex italic mt-1">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.notes}
                      </span>
                    )}
                  </div>
                </div>

                <div className="py-2">
                  <lable className="">Attachement</lable>
                  <div className="flex w-[620px]">
                    <div className="w-full h-[104px] border-2 border-tpGray py-2 content-center">
                      <div className="flex justify-center">
                        <button
                          className="text-royalBlue font-bold"
                          onClick={handleClick}
                        >
                          Add attachment
                        </button>
                        <input
                          ref={fileInputRef}
                          type="file"
                          className="hidden"
                          onChange={handleFileChange}
                        />
                      
                      </div>
                      <div className="flex justify-center">
                        <span className=" content-center">
                          Max file size : 20 MB
                        </span>
                        
                      </div>
                      {errors.attachemntFile && (
                      <span className="text-errorRed justify-center text-base flex italic">
                        <img src="alert.png" className="w-5 h-5 mr-1" />
                        {errors.attachemntFile}
                      </span>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[650px] justify-end flex py-3">
            <div className="flex justify-between">
              <button
                onClick={handleClose}
                className="uppercase bg-pearl text-white w-[200px] h-[35px] rounded-[10px] mx-5 font-semibold"
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                role="button"
                className="uppercase bg-lochmara text-white w-[200px] h-[35px] rounded-[10px] font-semibold content-center"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEditCustomer;
