import React from "react";
import Select from "../../../../components/Select";

const Categorize = () => {
  const options = [
    "January 1, 2024",
    "January 2, 2024",
    "January 3, 2024",
    // Add more options as needed
  ];
  return (
    <>
      <div className="w-full h-auto">
        <div className="flex gap-5 w-full h-9 font-sans mb-10">
          <div className="w-[25.5%] grid grid-cols-1 font-sans">
            <lable className="text-sm">Vendor/Customer</lable>
            {/* <select className="border-pearl border bg-white px-2 rounded-[5px] w-full h-9">
                    <option>All Transactions</option>
                </select> */}
            <Select
              options={options}
              placeholder="Add who you paid"
              width="w-[250px]" // Set dynamic width here
            />
          </div>
          <div className="w-[25.5%] grid grid-cols-1">
            <lable className="text-sm">Category *</lable>
            {/* <select className="border-pearl border bg-white px-2 rounded-[5px] w-full h-9">
                    <option>Select category</option>
                </select> */}
            <Select
              options={options}
              placeholder="Select category"
              width="w-[250px]" // Set dynamic width here
            />
          </div>
          <div className="w-[46.5%] grid grid-cols-1 float-end">
            <lable className="text-sm">Tags</lable>
            <input
              type="text"
              className="w-full h-9 border border-greyText rounded-md px-2"
            />
          </div>
        </div>
        <div className="">
          <div className="w-full my-5">
            <lable className="text-sm">Memo</lable>
            <input
              type="text"
              className="w-full h-9 border border-greyText rounded-md px-2"
            />
          </div>
        </div>
        <div>
          <div className="text-sm flex">
            <label className="font-semibold">Bank Datails : </label>
            <p className="uppercase"> online transfer to sam</p>
          </div>
        </div>
        <div className="flex gap-16 my-5">
          <div className="flex gap-2">
            <img src="attachment-icon.png" className="w-5 h-5" />
            <p className="text-sm text-lochmara">Add attachment</p>
          </div>
          <div className="flex gap-2">
            <img src="x-circle.png" className="w-5 h-5" />
            <p className="text-sm text-lochmara">Exclude</p>
          </div>
        </div>
        <div className="flex justify-end">
          <button className="bg-lochmara text-white w-[200px] h-[35px] text-sm font-bold uppercase rounded-[10px] mb-3">
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default Categorize;
