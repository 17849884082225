// src/components/Dialog.js
import React from 'react';
import ReactDOM from 'react-dom'

const ReceivePaymentDialog = ({ isOpen, onClose, children }) => {
  //if (!isOpen) return null;
  console.log("open",isOpen)
  console.log("onClose",onClose)

  // return (
  //   <div className="fixed inset-0 z-50 right-0 bg-black bg-opacity-50 flex justify-center overflow-y-scroll h-auto">
  //     <div className="relative bg-white w-[500px] h-screen my-5 rounded-md shadow-lg">
  //       {children}
  //     </div>
  //   </div>
  // );
  console.log(isOpen);
  return ReactDOM.createPortal(
    <div className={` ${isOpen ? 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50':''}`}>
      <div  className={`fixed top-0 right-0 h-full z-50 transform ${
      isOpen ? 'translate-x-0 opacity-100 transition-transform duration-700' : 'translate-x-full opacity-100 transition-transform duration-700'
    }  ease-in-out overflow-y-scroll `}>
     <div className="bg-white w-[500px] h-screen rounded-md shadow-lg">
        {children}
      </div>
    </div>
    </div>,
    document.body
  );
};

export default ReceivePaymentDialog;
