import React from "react";
import TaxDeduction from "./regular-payroll-tax-deduction/TaxDeduction";
import EmployeeSummary from "./regular-payroll-employee-summary/EmployeeSummary";
import Layout from "../../Layout";
import Tabs from "../../../components/Tabs";
import { IoChevronBackOutline } from "react-icons/io5";

const RegularPayroll = () => {
    const tabs = [
        {
          id: 'employeesummary',
          title: 'Employee Summary',
          content: <EmployeeSummary/>,
        },
        {
          id: 'payrollhistory',
          title: 'Taxes & Deductions',
          content: <TaxDeduction/>,
        },
      ];
  return (
    <>
      <Layout>
        <div className="bg-white w-full h-full overflow-y-scroll px-5 font-sans">
          <div className="">
            <div className="w-full">
              <div className="text-lg font-semibold font-sans mt-8 mb-8 flex">
              <IoChevronBackOutline className="mt-1 mr-2"/>
                <h1>Regular Payroll</h1>
              </div>
              <div className="flex gap-4">
                <div className="bg-tpGray w-[40%] h-[125px] px-6">
                  <p className="py-2 text-sm flex">Period" <p className="font-semibold mr-1"> June 2024 </p> | 30 Base Days</p>
                  <div className="flex justify-between py-3">
                    <div>
                        <p className="text-lg font-bold">$121,200.00</p>
                        <p className="text-sm">PAYROLL COST</p>
                    </div>
                    <div>
                        <p className="text-lg font-bold">$121,200.00</p>
                        <p className="text-sm">PAYROLL COST</p>
                    </div>
                  </div>
                </div>
                <div className="border h-[125px] w-[12%] border-tpGray text-center text-sm content-center">
                    <p className="text-sm font-normal">PAY DAY</p>
                    <p className="text-2xl">28</p>
                    <p className="text-sm font-normal">JUN, 2024</p>
                    <p className="pt-2 text-sm font-normal">20 Employees</p>
                </div>
                <div className="w-[23%]">
                    <div>
                        <p className="text-base font-normal pb-3">Taxes  & Deductions</p>
                    </div>
                    <div className="flex justify-between pb-3">
                        <p className="text-sm">Taxes</p>
                        <p className="text-sm">$3000.00</p>
                    </div>
                    <div className="flex justify-between pb-3">
                        <p className="text-sm">Pre-Tax Deductions</p>
                        <p className="text-sm">$2450.00</p>
                    </div>
                    <div className="flex justify-between pb-3">
                        <p className="text-sm">Post-Tax Deductions</p>
                        <p className="text-sm">$0.00</p>
                    </div>
                </div>
              </div>
            </div>
            <Tabs tabs={tabs} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default RegularPayroll;
