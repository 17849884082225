import React,{useState} from 'react'
import CustomDropdown from '../../../../components/custom-dropdown/CustomDropdown'
import Modal from '../../../../components/Modal';
import { useTranslation } from "react-i18next";
import BillMarkAsPaid from "../mark-as-paid/BillMarkAsPaid"
import ExpenseDialog from '../../../../components/Dialog';
import EditBill from '../view-edit-bill/EditBill';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";


const Unpaid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isPaidBillDialogOpen, setPaidBillDialogOpen] = useState(false);
  const [isPaidBillDialogClose, setPaidBillDialogClose] = useState(false);
  const [isEditBillDialogOpen, setIsEditBillDialogOpen] = useState(false);
  const [isEditBillDialogClose, setIsEditBillDialogClose] = useState(false);

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const openPaidBillDialog = () => setPaidBillDialogOpen(true);
  const openEditBillModal = () => setIsEditBillDialogOpen(true);

  const handlePaidBill = () => {
    setPaidBillDialogOpen((preventDefault) => !preventDefault);
  };
  const handleSubmitEditBill = () => {
    setIsEditBillDialogOpen((preventDefault) => !preventDefault);
  };
    const handleSelect = (value) => {
      if (value === "delete") {
        openDeleteModal();
      }
      if (value === "paid") {
        openPaidBillDialog();
      }
      if (value === "view") {
        openEditBillModal();
      }
      };
  return (
    <>
         <div className="py-10">
                <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 text-sm border-bombay text-left font-semibold uppercase">
                      <th className="w-[3%] py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </th>
                      <th className="w-[10%]">Vendor</th>
                      <th className="w-[7%]">Due Date</th>
                      <th className="w-[10%] text-right">Bill Amount</th>
                      <th className="w-[10%] text-right">Open Balance</th>
                      <th className="w-[13%] px-20">Status</th>
                      <th className="w-[13%] text-right px-2">ACTION</th>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">John Smith</td>
                      <td className="py-2">02/09/2024</td>
                      <td className="py-2 text-right">$200.00</td>
                      <td className="py-2 font-semibold text-right">$200.00</td>
                      <td className="py-2 px-20">Due in 15 days</td>
                      <td className="py-2 flex cursor-pointer justify-end">
                        
                        <a className="px-1 pt-2 text-lochmara font-semibold">
                          {" "}
                          Schedule Payment
                        </a>
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "paid", option: "Mark as paid" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "delete", option: "Delete Bill" },
                            ]}
                            onSelect={handleSelect}
                            width="w-[185px]"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">John Smith</td>
                      <td className="py-2">02/09/2024</td>
                      <td className="py-2 text-right">$200.00</td>
                      <td className="py-2 font-semibold text-right">$200.00</td>
                      <td className="py-2 px-20">Due in 15 days</td>
                      <td className="py-2  flex cursor-pointer justify-end">
                        
                        <a className="px-1 pt-2 text-lochmara font-semibold">
                          {" "}
                          Schedule Payment
                        </a>
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "paid", option: "Mark as paid" },
                              { value: "duplicate", option: "Duplicate" },
                              { value: "delete", option: "Delete Bill" },
                            ]}
                            onSelect={handleSelect}
                            width="w-[185px]"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
               
        </div>
        <Modal isOpen={isDeleteModalOpen}>
        <div className="relative">
          <button
            onClick={closeDeleteModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-6 h-6 mx-5 my-2" />
          </button>
          <img src="warning-icon.png" className="w-9 h-9 mx-auto mb-3" />
        </div>
        <h3 className="w-full text-2xl font-semibold mb-3 text-center content-center font-sans">
          Delete bill?
        </h3>
        <p className="text-sm font-sans font-semibold my-6 px-5">
        This unpaid bill will be deleted from your books.
        </p>
        <button
          onClick={closeDeleteModal}
          className="my-5 uppercase text-white bg-lochmara font-bold text-base w-[200px] h-[36px] rounded-[10px]"
        >
          {t("delete")}
        </button>
      </Modal>
      <ExpenseDialog isOpen={isPaidBillDialogOpen} onClose={isPaidBillDialogClose} height='h-[750px]'>
        <BillMarkAsPaid
          onSubmit={handlePaidBill}
          onClose={handlePaidBill}
        />
      </ExpenseDialog>
      <ExpenseDialog isOpen={isEditBillDialogOpen} onClose={isEditBillDialogClose}>
        <EditBill
          onSubmit={handleSubmitEditBill}
          onClose={handleSubmitEditBill}
        />
      </ExpenseDialog>
    </>
  )
}

export default Unpaid