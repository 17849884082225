import React, { useState, useRef } from "react";
import CustomDropdown from "../../../../components/custom-dropdown/CustomDropdown";

const BillMarkAsPaid = ({ onSubmit, onClose }) => {
  const fileInputRef = useRef(null);

  const [fileName, setFileName] = useState();

  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected file:", file.name);
      setFileName(file.name);
      // Handle the file upload logic here
    }
  };
  return (
    <>
      <div className="bg-bonJour h-auto w-full overflow-auto">
        <div className="bg-white w-full h-[90px] px-7 flex justify-between">
          <div className="content-center">
            <lable className="text-4xl font-sans font-semibold">
              Bill Payment
            </lable>
          </div>
          <div className="content-center">
            <div className="flex gap-8">
              <div className="w-6 h-6  cursor-pointer">
                <button onClick={onClose}>
                  <img src="cross-icon.png" className="content-center" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-[195px] mx-7 mt-5">
          <div className="px-5 py-3 flex">
            <div className="">
              <div className="flex gap-5">
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Vendor</lable>
                  </div>
                  <div className="text-sm font-sans w-[275px] h-[36px]">
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                </div>
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Bank / Credit Account</lable>
                  </div>
                  <div className="text-sm font-sans w-[275px] h-[36px]">
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-5 mt-4">
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Mailing Address</lable>
                  </div>
                  <div className="text-sm font-sans w-[336px] h-[70px]">
                    <textarea className="w-[336px] h-[70px] border rounded-md border-pearl resize-none outline-none focus:border-lochmara p-1 font-normal" />
                  </div>
                </div>
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Payment Date</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <input
                      type="date"
                      className="w-full h-full border border-pearl rounded-md px-2"
                    />
                  </div>
                </div>
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Bill No.</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <input
                      type="text"
                      className="w-full h-full border border-pearl rounded-md px-2 focus:outline-lochmara focus:outline-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end w-full">
              <div>
                <p className="flex justify-end text-sm font-sans font-light">
                  Amount Paid
                </p>
                <h1 className="font-semibold text-5xl font-sans">$200.00</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-auto mx-7 mb-4">
          <div className="float-end text-right px-5 mb-4 pt-2">
            <div className="text-sm font-sans font-light">
              <lable>Amount</lable>
            </div>
            <div className="text-sm font-sans w-[174px] h-[36px]">
              <input
                type="text"
                className="w-full h-full border text-right border-pearl rounded-md px-2 focus:outline-lochmara focus:outline-1"
              />
            </div>
          </div>
          <div className="my-[4px] px-5">
            {/* product or services */}
            <div className="pt-7">
              <table className="w-full mt-5">
                <tbody className="font-sans">
                  <tr className="border-b-2 text-base border-bombay text-left font-semibold uppercase">
                    <th className="w-[4%]">
                      <input type="checkbox" className="w-5 h-5 mt-1" />
                    </th>
                    <th className="w-[15%]">Description</th>
                    <th className="w-[10%]">Due Date</th>
                    <th className="w-[10%] text-right">Original Amount</th>
                    <th className="w-[15%] text-right">Open Balance</th>
                    <th className="w-[20%] text-right px-2">Payment</th>
                  </tr>
                  <tr className="border-b-2 text-sm font-normal border-bombay">
                    <td className="py-2">
                      <input type="checkbox" className="w-5 h-5 mt-1" />
                    </td>
                    <td className="py-2 flex">
                      <p className="text-lochmara font-semibold">
                        Bill # 23456{" "}
                      </p>{" "}
                      (05/30/2024)
                    </td>
                    <td className="py-2">02/09/2024</td>
                    <td className="py-2 text-right">$30.00</td>
                    <td className="py-2 text-right">$30.00</td>
                    <td className="py-2 flex cursor-pointer justify-end">
                      <div className="text-sm font-sans w-[174px] h-[36px]">
                        <input
                          type="text"
                          className="w-full h-full border text-right border-pearl rounded-md px-2 focus:outline-lochmara focus:outline-1"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="pl-5 w-full px-5 flex mt-10">
            <div className="text-sm font-sans font-normal py-2">
              <lable>Memo</lable>
              <div className="">
                <textarea className="w-[400px] h-[125px] border-2 border-tpGray resize-none outline-none focus:border-lochmara p-1 font-normal" />
              </div>
            </div>
            <div className="text-sm font-sans font-normal py-2 ml-5">
              <lable>Attachments</lable>
              <div className="w-[400px] h-[75px] border-2 border-tpGray p-1 font-normal content-center">
                <div className="flex justify-center">
                  <button
                    className="text-royalBlue font-semibold content-center"
                    onClick={handleClick}
                  >
                    Add attachment
                  </button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="flex justify-center">
                  <span className="content-center">Max file size : 10 MB</span>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="flex justify-end pb-5">
                <div className="right-12 gap-10 flex font-sans  text-base">
                  <p className="font-semibold">Amount to Apply :</p>
                  <p>$200.00</p>
                </div>
              </div>
              <div className="flex justify-end">
                <div className="right-16 gap-10 flex font-sans text-base">
                  <p className="font-semibold mr-4">Amount to Credit :</p>
                  <p>$0.00</p>
                </div>
              </div>
            </div>
          </div>
          <div className="content-end flex justify-end w-full pb-4 px-10">
            <button className="uppercase text-xs font-semibold text-white bg-lochmara rounded-[10px] px-2 py-1 w-[200px] h-[35px]">
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillMarkAsPaid;
