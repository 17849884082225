import React, { useState } from "react";
import Logo from "../Logo";
import Navbar from "../navbar/Navbar";
import { FaChevronDown,FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SideMenu = ({sidebarToggle,setSidebarToggle}) => {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(1);
  const handleOpen = (value) => {
    console.log(value);
    setOpen(open === value ? 0 : value);
  };
  return (
    <>
      <div className={`${sidebarToggle ? " hidden":"block"} ${open !== 0 ? " h-screen":"h-screen"} bg-lochmara w-[220px] justify-center `}>
        <div className='w-full h-[60px] flex justify-center mx-1 mt-5'>
           <img src='image.png' className="w-[186px] "/>
        </div>
        <div className="flex justify-center h-auto">
          <ul>
            <li className="mb-2 rounded font-sans font-semibold hover:shadow hover:bg-blue-600 hover:font-semibold pt-14">
              <Link to='/dashboard' className="text-white px-3 text-sm">
                  {t("dashboard")}
              </Link>
            </li>
            <li className="mb-2 rounded font-sans font-normal hover:shadow hover:bg-blue-600 hover:font-semibold py-2">
              <a href="#" className="text-white px-3 text-sm">
                  {t("transactions")}
              </a>
            </li>
            <li className="mb-2 rounded font-sans font-normal hover:shadow hover:bg-blue-600 hover:font-semibold py-2">
              <div className="flex text-white justify-between">
              <a href="#" className="text-white px-3 text-sm">
                  {t("sales")}
              </a>
              {
                open == 0 ? <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(1)}/> : open == 1 ? <FaChevronUp className="content-center cursor-pointer" onClick={()=>handleOpen(0)} />: <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(1)}/>
              }
              {/* <FaChevronDown className="content-center"  onClick={()=>setSubmenuToggle(!submenuToggle)}/>
              <FaChevronUp /> */}
              </div>
              <div className={`${open == 1 ? " block":"hidden"} flex justify-between text-sm font-sans font-normal`}>
                  <ul>
                    <li className="px-6 py-2 mt-2 text-white  hover:shadow hover:bg-blue-700 hover:font-semibold"><Link to="/sales">{t("salestransactions")}</Link></li>
                    <li className="px-6 py-2 text-white hover:shadow hover:bg-blue-700 hover:font-semibold"><Link to="/invoices">{t("invoices")}</Link></li>
                    <li className="px-6 py-2 text-white hover:shadow hover:bg-blue-700 hover:font-semibold"><Link to='/customers'>{t("customers")}</Link></li>
                  </ul>
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal hover:shadow hover:bg-blue-600 hover:font-semibold py-2">
            <div className="flex text-white justify-between">
              <a href="#" className="text-white px-3 text-sm">
                  {t("expenses")}
              </a>
              {
                open == 0 ? <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(2)}/> : open == 2 ? <FaChevronUp className="content-center cursor-pointer" onClick={()=>handleOpen(0)} />:<FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(2)}/>
              }
              </div>
              <div className={`${open == 2 ? " block":"hidden"} flex justify-between text-sm font-sans font-normal`}>
                  <ul>
                    <li className="px-6 py-2 mt-2 text-white hover:shadow hover:bg-blue-700 hover:font-semibold"><Link to="/expenses">{t("expenses")}</Link></li>
                    <li className="px-6 py-2 text-white hover:shadow hover:bg-blue-700 hover:font-semibold"><a href="">{t("bills")}</a></li>
                    <li className="px-6 py-2 text-white hover:shadow hover:bg-blue-700 hover:font-semibold"><a href="">{t("vendors")}</a></li>
                  </ul>
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal hover:shadow hover:bg-blue-600 hover:font-semibold py-2">
            <div className="flex text-white justify-between">
              <a href="#" className="text-white px-3 text-sm">
                  {t("products&services")}
              </a>
              {
                open == 0 ? <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(3)}/> : open == 3 ? <FaChevronUp className="content-center cursor-pointer" onClick={()=>handleOpen(0)} /> : <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(3)}/>
              }
              </div>
              <div className={`${open == 3 ? " block":"hidden"} flex justify-between text-sm`}>
                  <ul>
                    <li className="px-6 py-2 mt-2 text-white hover:shadow hover:bg-blue-700 hover:font-semibold"><a href="">{t("products")}</a></li>
                    <li className="px-6 py-2 text-white hover:shadow hover:bg-blue-700 hover:font-semibold"><a href="">{t("category")}</a></li>
                  </ul>
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal hover:shadow hover:bg-blue-600 hover:font-semibold py-2">
            <div className="flex text-white justify-between">
              <a href="#" className="text-white px-3 text-sm">
                {t( "payroll")}
              </a>
              {
                open == 0 ? <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(4)}/> : open == 4 ? <FaChevronUp className="content-center cursor-pointer" onClick={()=>handleOpen(0)} /> : <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(4)}/>
              }
              </div>
              <div className={`${open == 4 ? " block":"hidden"} flex justify-between text-sm`}>
                  <ul>
                    <li className="px-6 py-2 mt-2 text-white hover:shadow hover:bg-blue-700 hover:font-semibold"><a href="">{t("employees")}</a></li>
                    <li className="px-6 py-2 text-white hover:shadow hover:bg-blue-700 hover:font-semibold"><a href="">{t("contractors")}</a></li>
                  </ul>
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal hover:shadow hover:bg-blue-600 hover:font-semibold py-2">
            <div className="flex text-white justify-between">
              <a href="#" className="text-white px-3 text-sm">
                  {t("reports")}
              </a>
              {
                open == 0 ? <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(5)}/> : open == 5 ? <FaChevronUp className="content-center cursor-pointer" onClick={()=>handleOpen(0)} /> : <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(5)}/>
              }
              </div>
            </li>
            <li className="mb-2 rounded font-sans font-normal hover:shadow hover:bg-blue-600 hover:font-semibold py-2">
            <div className="flex text-white justify-between">
              <a href="#" className="text-white px-3 text-sm">
                  {t("settings")}
              </a>
              {
                open == 0 ? <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(6)}/> : open == 6 ? <FaChevronUp className="content-center cursor-pointer" onClick={()=>handleOpen(0)} /> : <FaChevronDown className="content-center cursor-pointer"  onClick={()=>handleOpen(6)}/>
              }
              </div>
            </li>
          </ul>
        </div>
        <div className='fixed bottom-1 left-2 w-[203px] h-[17px]'>
        <img src='copyright.png'/>
      </div>
      </div>
    </>
  );
};

export default SideMenu;
