import React,{useState} from "react";
import Layout from "../../Layout";
import { FaChevronDown } from "react-icons/fa";
import ForReview from "../bills/for-review/ForReview.js";
import Unpaid from "../bills/unpaid/Unpaid.js";
import Paid from "../bills/paid/Paid.js";
import Tabs from "../../../components/Tabs";
import CreateBill from "./create-bill/CreateBill.js";
import EditBill from "./view-edit-bill/EditBill.js"
import Dialog from "../../../components/Dialog.js";

const Bill = () => {
  const tabs = [
    {
      id: "forreview",
      title: "For Review",
      content: <ForReview />,
    },
    {
      id: "unpaid",
      title: "Unpaid",
      content: <Unpaid />,
    },
    {
      id: "paid",
      title: "Paid",
      content: <Paid />,
    },
  ];

  const [isBillDialogOpen, setIsBillDialogOpen] = useState(false);
  const [isBillDialogClose, setIsBillDialogClose] = useState(false);

  const handleSubmitBill = () => {
    setIsBillDialogOpen((preventDefault) => !preventDefault);
  };
 
  return (
    <>
      <Layout>
        <div className=" bg-white w-full h-full overflow-y-scroll px-5">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold font-sans mt-10">
                <h1>Bill</h1>
              </div>
              <div className="float-right">
                <button className="uppercase bg-lochmara pl-14 font-semibold text-sm justify-center text-white rounded-[10px] w-[200px] h-[35px] flex py-2"
                onClick={handleSubmitBill}>
                  New Bill
                  <FaChevronDown className="cursor-pointer mt-1 ml-12" />
                </button>
              </div>
            </div>
            <div className="py-2 w-full">
              <Tabs tabs={tabs} />
            </div>
          </div>
        </div>
      </Layout>
      <Dialog isOpen={isBillDialogOpen} onClose={isBillDialogClose}>
        <CreateBill
          onSubmit={handleSubmitBill}
          onClose={handleSubmitBill}
        />
      </Dialog>
      
    </>
  );
};

export default Bill;
