import React, { useState, useRef } from "react";
import CustomDropdown from "../../../../components/custom-dropdown/CustomDropdown";

const EditBill = ({ onSubmit, onClose }) => {
  const fileInputRef = useRef(null);
  const [inputs, setInputs] = useState([
    { product: "", description: "", quantity: "", rate: "", amount: "" },
  ]);
  const [fileName, setFileName] = useState();

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      { product: "", description: "", quantity: "", rate: "", amount: "" },
    ]);
  };

  const handleDeleteInput = (index) => {
    console.log("index :", index);
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected file:", file.name);
      setFileName(file.name);
      // Handle the file upload logic here
    }
  };
  return (
    <>
      <div className="bg-bonJour h-screen w-full overflow-y-scroll">
        <div className="bg-white w-full h-[90px] px-7 flex justify-between">
          <div className="content-center">
            <lable className="text-4xl font-sans font-semibold">Bill</lable>
          </div>
          <div className="content-center">
            <div className="flex gap-8">
              <div className="w-6 h-6  cursor-pointer">
                <button onClick={onClose}>
                  <img src="cross-icon.png" className="content-center" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-[260px] mx-7 mt-5">
          <div className="px-5 py-3 flex">
            <div className="">
              <div className="flex gap-5">
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Vendor</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                </div>
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Payment Account</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                </div>
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Bill No.</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <input
                      type="text"
                      className="w-full h-full border border-pearl rounded-md px-2 focus:outline-lochmara focus:outline-1"
                    />
                  </div>
                </div>
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Bill Date</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <input
                      type="date"
                      className="w-full h-full border border-pearl rounded-md px-2"
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-5 mt-4">
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Mailing Address</lable>
                  </div>
                  <div className="text-sm font-sans w-[422px] h-[70px]">
                    <textarea className="w-[422px] h-[70px] border rounded-md border-pearl resize-none outline-none focus:border-lochmara p-1 font-normal" />
                  </div>
                </div>

                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Terms</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <CustomDropdown
                      options={[
                        {
                          value: "alltransactions",
                          option: "All Transactions",
                        },
                        { value: "expense", option: "Expense" },
                        { value: "bill", option: "Bill" },
                        { value: "payment", option: "Bill payment" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                </div>
                <div>
                  <div className="text-sm font-sans font-light">
                    <lable>Due Date</lable>
                  </div>
                  <div className="text-sm font-sans w-[200px] h-[36px]">
                    <input
                      type="date"
                      className="w-full h-full border border-pearl rounded-md px-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end w-full">
              <div>
                <p className="flex justify-end text-sm font-sans font-light">
                  Bill Amount
                </p>
                <h1 className="font-semibold text-5xl font-sans">$200.00</h1>
              </div>
            </div>
          </div>
          <div className="px-5">
            <div>
              <div className="text-sm font-sans font-light">
                <lable>Tag</lable>
              </div>
              <div className="text-sm font-sans w-[860px] h-[36px]">
                <input
                  type="text"
                  className="w-full h-full border border-pearl rounded-md px-2 focus:outline-lochmara focus:outline-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-auto mx-7 mb-4">
          <div className="my-[4px] px-5">
            {/* product or services */}
            <div className="pt-4">
              <table className="w-full">
                <tbody className="text-base font-sans font-semibold">
                  <tr className="border-b-2 border-tpGray text-left">
                    <th className="w-[2%]"></th>
                    <th className="w-[2%]"></th>
                    <th className="w-[2%] px-1">#</th>
                    <th className="w-[5%] px-1">Category</th>
                    <th className="w-[27.4%]">Description</th>
                    <th className="w-[15%] px-1 text-right">Amount</th>
                    <th className="w-[6%] px-1"></th>
                  </tr>
                  {inputs.map((item, index) => (
                    <tr className="border-b-2 border-tpGray" key={index}>
                      <td>
                        {index === inputs.length - 1 && (
                          <button onClick={() => handleAddInput()}>
                            <img src="plus.png" className="w-6 h-6 mt-2" />
                          </button>
                        )}
                      </td>
                      <td className="py-1">
                        <img src="reorder-icon.png" className="w-6 h-6" />
                      </td>
                      <td className="py-1">{index}</td>
                      <td className="py-1 px-1">
                        <div className="text-sm font-sans w-[200px] h-[36px]">
                          <CustomDropdown
                            options={[
                              {
                                value: "alltransactions",
                                option: "All Transactions",
                              },
                              { value: "expense", option: "Expense" },
                              { value: "bill", option: "Bill" },
                              { value: "payment", option: "Bill payment" },
                            ]}
                            border="border border-pearl rounded-md"
                            btnCss="text-white bg-bombay"
                          />
                        </div>
                      </td>
                      <td className="py-1">
                        <input
                          name="description"
                          type="text"
                          className="border-2 border-tpGray w-full p-1 rounded-md outline-none focus:border-lochmara font-normal font-sans"
                        />
                      </td>
                      <td className="py-1 pl-8">
                        <input
                          name="amount"
                          type="number"
                          className="border-2 border-tpGray w-full p-1 rounded-md outline-none focus:border-lochmara text-right font-normal font-sans"
                        />
                      </td>
                      <td>
                        {inputs.length > 1 && (
                          <button onClick={() => handleDeleteInput(index)}>
                            <img
                              src="trash-bin-icon.png"
                              className="w-6 h-6 cursor-pointer ml-6 mt-1"
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pl-5 w-[89.6%] flex">
            <div className="text-sm font-sans font-normal py-2">
              <lable>Memo</lable>
              <div className="">
                <textarea className="w-[400px] h-[125px] border-2 border-tpGray resize-none outline-none focus:border-lochmara p-1 font-normal" />
              </div>
            </div>
            <div className="text-sm font-sans font-normal py-2 ml-5">
              <lable>Attachments</lable>
              <div className="w-[400px] h-[75px] border-2 border-tpGray p-1 font-normal content-center">
                <div className="flex justify-center">
                  <button
                    className="text-royalBlue font-semibold content-center"
                    onClick={handleClick}
                  >
                    Add attachment
                  </button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="flex justify-center">
                  <span className="content-center">Max file size : 10 MB</span>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="flex justify-end">
                <div className="right-12 gap-10 flex font-sans font-semibold text-base">
                  <p>Total</p>
                  <p>$200.00</p>
                </div>
              </div>
            </div>
          </div>
          <div className="content-end flex justify-end w-full py-6 px-10">
            <button className="uppercase text-xs font-semibold text-white bg-lochmara rounded-[10px] px-2 py-1 w-[200px] h-[35px]">
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBill;
