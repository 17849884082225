import React, { useRef } from "react";
import { useState } from "react";

const CreateInvoice = ({ onSubmit, onClose }) => {
  const fileInputRef = useRef(null);
  const [inputs, setInputs] = useState([
    { product: "", description: "", quantity: "", rate: "", amount: "" },
  ]);
  const [fileName, setFileName] = useState();

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      { product: "", description: "", quantity: "", rate: "", amount: "" },
    ]);
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    console.log("index :", index);
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected file:", file.name);
      setFileName(file.name);
      // Handle the file upload logic here
    }
  };
  return (
    <>
      <div className="bg-bonJour w-full h-auto overflow-auto">
        {/* Header */}
        <div className="bg-white w-full h-[90px] px-5 pt-2 flex justify-between">
          <div className="content-center">
            <lable className="text-3xl font-sans font-normal">
              Invoice CBI 1001
            </lable>
          </div>
          <div className="content-center">
            <div className="flex gap-8">
              <div className="w-6 h-6 cursor-pointer">
                <img src="pdf-icon.png" className="content-center" />
              </div>
              <div className="w-6 h-6  cursor-pointer">
                <button onClick={onClose}>
                  <img src="cross-icon.png" className="content-center" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* details */}
        <div className="mx-6 mt-16">
          <div className="bg-white h-auto py-6 px-8">
            <div className="flex justify-between">
              <lable className="text-blueRibbon font-semibold text-5xl">
                INVOICE
              </lable>
              <img src="image.png" className="w-[186px] h-[60px]" />
            </div>
            <div className="h-[87px] w-[552px] mt-8">
              <div className="flex justify-between font-sans text-lg">
                <div className="leading-tight">
                  <p className="font-bold">Cambodia Inc</p>
                  <p>112 main street STE 300</p>
                  <p>Siem Reap</p>
                  <p>Cambodia</p>
                </div>
                <div className="leading-tight">
                  <p>someone@gmail.com</p>
                  <p>Ph: +14 343 999 9999</p>
                  <p>Fax: +14 343 999 9999</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-frost h-auto w-full px-8 py-4 gap-10 flex font-sans">
            <div className="py-4 w-[40%]">
              <div className="text-base py-2">
                <select className="w-[450px] px-3 outline-none cursor-pointer h-[35px]">
                  <option>Select a customer</option>
                </select>
              </div>
              <div className="w-[450px]">
                <div className="text-lg flex justify-between font-sans">
                  <div className="leading-tight">
                    <p className="font-bold ">Sample Customer Name</p>
                    <p>112 main street STE 300</p>
                    <p>Siem Reap</p>
                    <p>Cambodia</p>
                  </div>
                  <div className="leading-tight">
                    <p>someone@gmail.com</p>
                    <p>Ph: +14 343 999 9999</p>
                    <p>Fax: +14 343 999 9999</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[30%] px-10">
              <div className="w-[250px]">
                <lable className="w-full font-sans text-base font-normal">
                  Invoice no.
                </lable>
                <input
                  type="number"
                  className="w-full h-[35px] p-1 outline-none cursor-text appearance-none"
                />
              </div>
              <div className="w-[250px] py-2">
                <lable className="w-full">Term</lable>
                <select className="w-[250px] h-[35px] px-2 outline-none cursor-pointer">
                  <option>Net 30</option>
                </select>
              </div>
            </div>
            <div className="w-[30%]">
              <div className="w-[250px]">
                <lable className="w-full font-sans text-base font-normal">
                  Invoice date
                </lable>
                <input
                  type="date"
                  className="w-[250px] h-[35px] px-2 outline-none cursor-pointer"
                />
              </div>
              <div className="w-[250px] text-base py-2">
                <lable className="w-full">Due date</lable>
                <input
                  type="date"
                  className="w-[250px] h-[35px] px-2 outline-none cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Form */}
        <div className="mx-6 mt-2 bg-white h-auto p-2 px-6 mb-24">
          <div className="bg-white w-full">
            {/* tag */}
            <lable className="text-base text-greyText">Tags (hidden)</lable>
            <div className="">
              <input className="border w-full h-[45px] border-bombay rounded-[5px] outline-none " />
            </div>
          </div>
          <div className="my-3">
            {/* product or services */}
            <div className=" font-sans text-xl font-bold">
              <h1>Product or service</h1>
            </div>
            <div className="pt-4">
              <table className="w-full">
                <tbody className="text-base font-sans">
                  <tr className="border-b-2 border-tpGray text-left uppercase">
                    <th className="w-[2%]"></th>
                    <th className="w-[2%]"></th>
                    <th className="w-[2%] px-1">#</th>
                    <th className="w-[10%] px-1">Service Date</th>
                    <th className="w-[10%] px-1">Product/service</th>
                    <th className="w-[5%] px-1">SKU</th>
                    <th className="w-[18%] px-1">Description</th>
                    <th className="w-[3%] px-1">Quantity</th>
                    <th className="w-[7%] px-1">Rate</th>
                    <th className="w-[10%] px-1">Amount</th>
                    <th className="w-[3%] px-1"></th>
                  </tr>
                  {inputs.map((item, index) => (
                    <tr className="border-b-2 border-tpGray" key={index}>
                      <td>
                        {index === inputs.length - 1 && (
                          <button onClick={() => handleAddInput()}>
                            <img src="plus.png" className="w-6 h-6 mt-2" />
                          </button>
                        )}
                      </td>
                      <td className="py-1">
                        <img src="reorder-icon.png" className="w-6 h-6" />
                      </td>
                      <td className="py-1">{index}</td>
                      <td className="py-1 px-1">
                        <input
                          type="date"
                          className="border-2 border-tpGray p-1 w-full h-[35px] rounded-sm outline-none cursor-pointer focus:border-lochmara"
                        />
                      </td>
                      <td className="py-1 px-1">
                        <select
                          name="product"
                          className=" w-full h-[35px] border-2 border-tpGray p-1 rounded-sm outline-none cursor-pointer focus:border-lochmara"
                        >
                          <option></option>
                        </select>
                      </td>
                      <td className="py-1"></td>
                      <td className="py-1 px-1">
                        <input
                          name="description"
                          type="text"
                          className="border-2 border-tpGray w-full p-1 rounded-sm outline-none focus:border-lochmara"
                        />
                      </td>
                      <td className="py-1 px-1">
                        <input
                          name="quantity"
                          type="number"
                          className="border-2 border-tpGray p-1 w-full rounded-sm outline-none focus:border-lochmara"
                        />
                      </td>
                      <td className="py-1 px-1">
                        <input
                          name="rate"
                          type="number"
                          className="border-2 border-tpGray p-1 w-full rounded-sm outline-none focus:border-lochmara"
                        />
                      </td>
                      <td className="py-1 px-1">
                        <input
                          name="amount"
                          type="number"
                          className="border-2 border-tpGray p-1 w-full rounded-sm outline-none focus:border-lochmara"
                        />
                      </td>
                      <td>
                        {inputs.length > 1 && (
                          <button onClick={() => handleDeleteInput(index)}>
                            <img
                              src="trash-bin-icon.png"
                              className="w-6 h-6 cursor-pointer ml-3 mt-1"
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-between h-auto relative font-sans">
            <div className="w-[51%]">
              <div className="text-base font-semibold py-2">
                <lable>Note to customer</lable>
                <div className="">
                  <textarea className="w-full h-[125px] border-2 border-tpGray resize-none outline-none focus:border-lochmara p-1 font-normal" />
                </div>
              </div>
              <div className="text-base font-semibold py-2">
                <lable>Memo on statement (hidden)</lable>
                <div className="">
                  <textarea className="w-full h-[125px] border-2 border-tpGray resize-none outline-none focus:border-lochmara p-1 font-normal" />
                </div>
              </div>
              <div className="text-base py-2 w-full">
                <lable className="font-semibold">Attachment</lable>
                <div className="flex">
                  <div className="w-[83%] h-[75px] border-2 border-tpGray py-3">
                    <div className="flex justify-center">
                      <button
                        className="text-royalBlue font-bold content-center"
                        onClick={handleClick}
                      >
                        Add attachment
                      </button>
                      <input
                        ref={fileInputRef}
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="flex justify-center">
                      <span className="content-center">
                        Max file size : 10 MB
                      </span>
                    </div>
                  </div>
                  <div
                    className={`${
                      fileName ? "block" : "hidden"
                    } w-[13%] h-[75px]`}
                  >
                    <div className="bg-pearl w-full h-full ml-7">
                      <button
                        className="float-right -mt-2 -mr-2 w-6 h-6 cursor-pointer"
                        onClick={() => setFileName()}
                      >
                        <img src="cross-circle-icon.png" />
                      </button>
                      <div className="w-full px-2 py-3 text-center">
                        <p className="break-words  text-white font-semibold text-xs">
                          {fileName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[42%] h-auto ">
              <div class=" border-b-2 border-greyText py-3">
                <div class="flex items-center justify-between py-1">
                  <p class="text-base font-medium text-greyText">Subtotal</p>
                  <p class="text-base font-semibold text-greyText">$0.00</p>
                </div>
                <div class="flex items-center justify-between py-1">
                  <p class="text-base font-medium text-greyText">
                    Sales tax/VAT/GST
                  </p>
                  <p class="text-base font-semibold text-greyText">$0.00</p>
                </div>
                <div class="flex items-center justify-between gap-3.5 py-1">
                  <div className="w-[50%] flex justify-between">
                    <p class="text-base font-medium text-greyText pr-10">
                      Discount
                    </p>
                    <div className="w-[77px] h-[25px] border flex">
                      <div className="bg-salem w-[50%] h-[25px] text-white">
                        <p className="text-base font-bold pb-1 px-3 content-center">
                          %
                        </p>
                      </div>
                      <div className="bg-white w-[50%] h-[23px] ">
                        <p className="text-base font-bold pb-1 px-3 content-center">
                          $
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[50%] flex justify-between">
                    <input
                      type="text"
                      placeholder="0%"
                      className="w-[77px] h-[25px] border focus:border-lochmara outline-none px-1 text-base text-right mr-10"
                    />
                    <p class="text-base font-semibold text-greyText">$0.00</p>
                  </div>
                </div>
                <div class="flex items-center justify-between py-1">
                  <p class="text-base font-medium text-greyText">Shipping</p>
                  <p class="text-base font-semibold text-greyText">$0.00</p>
                </div>
              </div>
              <div class="flex items-center justify-between py-1">
                <p class="text-base font-semibold">Invoice total</p>
                <p class="text-base font-semibold text-greyText">$0.00</p>
              </div>
              <div className="absolute right-0 bottom-0 p-8">
                <button className="uppercase text-xs font-semibold text-white bg-lochmara rounded-[10px] px-2 py-1 w-[200px] h-[35px]">
                  Save & Download pdf
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvoice;
