import React, { useState } from "react";
import CustomDropdown from "../../../../components/custom-dropdown/CustomDropdown";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/Modal";
import Dialog from "../../../../components/Dialog";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import PayBill from "../pay-bill/PayBill";

const Paid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isPaidDialogOpen, setPaidDialogOpen] = useState(false);
  const [isPaidDialogClose, setPaidDialogClose] = useState(false);

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const openPaidDialog = () => setPaidDialogOpen(true);

  const handlePaid = () => {
    setPaidDialogOpen((preventDefault) => !preventDefault);
  };

  const handleSelect = (value) => {
    if (value === "editbill") {
      openPaidDialog();
    }
    if (value === "delete") {
      openDeleteModal();
    }
  };
  return (
    <>
      <div className="py-10">
        <table className="w-full">
          <tbody className="font-sans">
            <tr className="border-b-2 text-sm border-bombay text-left font-semibold uppercase">
              <th className="w-[10%]">Vendor</th>
              <th className="w-[7%]">Due Date</th>
              <th className="w-[7%] text-right">Bill Amount</th>
              <th className="w-[10%] text-right">Open Balance</th>
              <th className="w-[13%] px-20">Status</th>
              <th className="w-[12%] px-2 text-right">ACTION</th>
            </tr>
            <tr className="border-b-2 text-sm font-normal border-bombay">
              <td className="py-2">John Smith</td>
              <td className="py-2">02/09/2024</td>
              <td className="py-2 text-right">$200.00</td>
              <td className="py-2 font-semibold text-right">$200.00</td>
              <td className="py-2 px-20">Paid on 02/09/2024</td>
              <td className="py-2  flex cursor-pointer justify-end">
                <a className="pt-2 text-lochmara font-semibold">
                  {" "}
                  Schedule Payment
                </a>
                <div className="">
                  <CustomDropdown
                    options={[
                      { value: "editbill", option: "View/Edit" },
                      { value: "delete", option: "Delete Bill" },
                    ]}
                    onSelect={handleSelect}
                    width="w-[114px]"
                  />
                </div>
              </td>
            </tr>
            <tr className="border-b-2 text-sm font-normal border-bombay">
              <td className="py-2">John Smith</td>
              <td className="py-2">02/09/2024</td>
              <td className="py-2 text-right">$200.00</td>
              <td className="py-2 font-semibold text-right">$200.00</td>
              <td className="py-2 px-20">Paid on 02/09/2024</td>
              <td className="py-2 flex cursor-pointer justify-end">
                <a className="pt-2 text-lochmara font-semibold ">
                  {" "}
                  Schedule Payment
                </a>
                <div className="">
                  <CustomDropdown
                    options={[
                      { value: "/editbill", option: "View/Edit" },
                      { value: "delete", option: "Delete Bill" },
                    ]}
                    onSelect={handleSelect}
                    width="w-[114px]"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal isOpen={isDeleteModalOpen} width="w-[650px]" height="h-[400px]">
        <div className="relative">
          <button
            onClick={closeDeleteModal}
            className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <img src="cross-icon.png" className="w-6 h-6 mx-5 my-2" />
          </button>
          <img
            src="warning-icon.png"
            className="w-[56px] h-[57px] mx-auto mb-8"
          />
        </div>
        <h3 className="w-full text-5xl font-semibold mb-14 text-center content-center font-sans">
          Delete bill?
        </h3>
        <p className="text-2xl font-sans font-semibold my-8 px-5">
          You already paid this bill. Deleting it will affect your books.
        </p>
        <button
          onClick={closeDeleteModal}
          className="my-5 uppercase text-white bg-pearl mr-8 font-bold text-base w-[200px] h-[36px] rounded-[10px]"
        >
          {t("cancel")}
        </button>
        <button
          onClick={closeDeleteModal}
          className="my-5 uppercase text-white bg-lochmara font-bold text-base w-[200px] h-[36px] rounded-[10px]"
        >
          {t("delete")}
        </button>
      </Modal>
      <Dialog
        isOpen={isPaidDialogOpen}
        onClose={isPaidDialogClose}
        height="h-[750px]"
      >
        <PayBill onSubmit={handlePaid} onClose={handlePaid} />
      </Dialog>
    </>
  );
};

export default Paid;
