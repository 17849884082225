import React, { useState } from "react";
import Layout from "../../Layout";
import CustomDropdown from "../../../components/custom-dropdown/CustomDropdown";
import AddEmployee from "../add-employee/AddEmployee";
import Dialog from "../../../components/Dialog";
import AddPayment from "../add-payment/AddPayment";
import Modal from "../../../components/Modal";
import { useTranslation } from "react-i18next";

const EmployeeList = () => {
  const { t } = useTranslation();
  const [isEmployeeDialogOpen, setIsEmployeeDialogOpen] = useState(false);
  const [isEmployeeDialogClose, setIsEmployeeDialogClose] = useState(false);

  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isPaymentDialogClose, setIsPaymentDialogClose] = useState(false);

  const [isEmployeeExitModalOpen, setIsEmployeeExitModalOpen] = useState(false);
  const [isEmployeeExitModalClose, setIsEmployeeExitModalClose] = useState(false);

  const openPaymentModal = () => setIsPaymentDialogOpen(true);
  const closePaymentModal = () => setIsPaymentDialogOpen(false);

  const openEmployeeExitModal = () => setIsEmployeeExitModalOpen(true);
  const closeEmployeeExitModal = () => setIsEmployeeExitModalOpen(false);

  const handleSubmitEmployee = () => {
    setIsEmployeeDialogOpen((preventDefault) => !preventDefault);
  };

  const handleSubmitPayment = () => {
    setIsPaymentDialogOpen((preventDefault) => !preventDefault);
  };

  const handleSelect = (value) => {
    if (value === "view") {
      openPaymentModal();
    }
    if (value === "exit") {
      openEmployeeExitModal();
    }
  };

  return (
    <>
      <Layout>
        <div className="bg-white w-full h-full overflow-y-scroll px-5 mb-2">
          <div className="">
            <div className="w-full">
              <div className="text-xl font-semibold font-sans mt-14 flex justify-between">
                <h1>Employees</h1>

                <button
                  className="uppercase bg-lochmara text-sm font-sans font-bold justify-center text-white rounded-[10px] w-[200px] h-[36px] flex py-2"
                  onClick={handleSubmitEmployee}
                >
                  ADD EMPLOYEE
                </button>
              </div>
            </div>

            <div className="py-10">
              <div className="justify-between flex">
                <div className="flex row gap-3 w-full pb-7">
                  <div className="">
                    <div className="text-sm font-sans font-normal">
                      <lable>Search</lable>
                    </div>
                    <div className="text-sm font-normal">
                      <div className="relative">
                        <input
                          type="search"
                          placeholder="Find product and services"
                          className="w-[253px] h-[36px] py-1 pl-2  border border-bombay rounded-md text-gray-800 focus:outline-none focus:ring-1 focus:ring-lochmara"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-[200px]">
                    <div className="text-sm font-sans font-normal">
                      <lable>Employee Status</lable>
                    </div>
                    <CustomDropdown
                      options={[
                        {
                          value: "newdepartment",
                          option: "New Department",
                        },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                  <div className="w-[200px]">
                    <div className="text-sm font-sans font-normal">
                      <lable>Employement Type</lable>
                    </div>
                    <CustomDropdown
                      options={[
                        { value: "designation", option: "Add Designation" },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                    />
                  </div>
                  <div className="w-[200px]">
                    <div className="text-sm font-sans font-normal">
                      <lable>Department</lable>
                    </div>
                    <CustomDropdown
                      options={[
                        {
                          value: "newdepartment",
                          option: "New Department",
                        },
                      ]}
                      border="border border-pearl rounded-md"
                      btnCss="text-white bg-bombay"
                      onSelect={handleSelect}
                    />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 text-sm border-bombay text-left font-semibold">
                      <th className="w-[3%] ">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </th>
                      <th className="w-[25%]">
                        <div className="flex ml-3">Employee Name</div>
                      </th>
                      <th className="w-[12%]">Date of joining</th>
                      <th className="w-[13%]">Employement Type</th>
                      <th className="w-[10%]">Status</th>
                      <th className="w-[13%] text-right">Cost to Company</th>
                      <th className="w-[15%] pr-2 text-right">ACTION</th>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2 flex">
                        <img
                          src="default-img.png"
                          className="w-[75px] h-[75px]"
                        />
                        <p className="content-center text-wrap w-[200px] overflow-clip">
                          John Smith john.smith@company.com Department: HR
                          Designation: HR Manager
                        </p>
                      </td>
                      <td className="py-2">12-Nov-2021</td>
                      <td className="py-2">Permanent</td>
                      <td className="py-2">Active</td>
                      <td className="py-2 text-right">120,000</td>
                      <td className="">
                        <a className="flex justify-end">
                          <p
                            className="content-center text-lochmara font-sans font-semibold text-sm"
                            onClick={openPaymentModal}
                          >
                            Edit |
                          </p>
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "delete", option: "Delete" },
                              { value: "exit", option: "Exit Process" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b-2 text-sm font-normal border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2 flex">
                        <img
                          src="default-img.png"
                          className="w-[75px] h-[75px]"
                        />
                        <p className="content-center text-wrap w-[200px] overflow-clip">
                          John Smith john.smith@company.com Department: HR
                          Designation: HR Manager
                        </p>
                      </td>
                      <td className="py-2">12-Nov-2021</td>
                      <td className="py-2">Permanent</td>
                      <td className="py-2">Active</td>
                      <td className="py-2 text-right">120,000</td>
                      <td className="">
                        <a className="flex justify-end">
                          <p
                            className="content-center text-lochmara font-sans font-semibold text-sm"
                            onClick={openPaymentModal}
                          >
                            Edit |
                          </p>
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              { value: "delete", option: "Delete" },
                              { value: "exit", option: "Exit Process" },
                            ]}
                            onSelect={handleSelect}
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal
        isOpen={isEmployeeExitModalOpen}
        width="w-[550px]"
        height="h-[300px]"
      >
        <div className="relative px-6">
          <div className="w-full text-lg font-semibold font-sans my-3 justify-between flex border-b-4 border-pearl">
            <div className="">
              <h1>Employee Exit Process</h1>
            </div>
          </div>
          <div className="w-full flex">
              <div>
                <div className="flex text-start">
                  <div className="w-[200px] py-1">
                    <lable className="text-sm font-semibold font-sans">Date of Exit</lable>
                    <div>
                      <input
                        type="date"
                        className="w-[200px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex text-start">
                  <div className="w-[500px] py-1">
                    <lable className="text-sm font-semibold font-sans">Reason</lable>
                    <div>
                      <input
                        type="text"
                        className="w-[500px] h-[36px] border border-pearl text-start focus:border-lochmara focus:outline-0 p-2 resize-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="w-full justify-end flex pt-6">
          <div className="flex justify-between">
            <button
              onClick={closeEmployeeExitModal}
              className="uppercase bg-pearl text-sm text-white w-[200px] h-[36px] rounded-[10px] mx-5 font-bold"
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              role="button"
              onClick={closeEmployeeExitModal}
              className="uppercase bg-lochmara text-sm text-white w-[200px] h-[36px] rounded-[10px] font-bold content-center"
            >
              {t("save")}
            </button>
          </div>
        </div>
        </div>
      </Modal>
      <Dialog
        isOpen={isEmployeeDialogOpen}
        onClose={isEmployeeDialogClose}
        height="h-[1080px]"
        width="w-[750px]"
      >
        <AddEmployee
          onSubmit={handleSubmitEmployee}
          onClose={handleSubmitEmployee}
        />
      </Dialog>
      <Dialog
        isOpen={isPaymentDialogOpen}
        onClose={isPaymentDialogClose}
        height="h-[720px]"
        width="w-[750px]"
      >
        <AddPayment
          onSubmit={handleSubmitPayment}
          onClose={handleSubmitPayment}
        />
      </Dialog>
    </>
  );
};

export default EmployeeList;
