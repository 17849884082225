import React from 'react'

const TaxDeduction = () => {
  return (
    <>
        <>
          <div className='font-sans'>
            <div>
              <h1 className='text-lg font-semibold pb-2'>Tax Details</h1>
              <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 border-bombay w-full">
                      <th className="text-sm font-semibold grid justify-items-start pl-2 py-2">TAX NAME</th>
                      <th className="text-sm font-semibold text-center py-2">PAID BY EMPLOYER</th>
                      <th className="text-sm font-semibold grid justify-items-end pr-2 py-2">PAID BY EMPLOYEE</th>
                    </tr>
                    <tr className="text-sm font-normal">
                      <td className="py-2 grid justify-items-start pl-2">Income Tax</td>
                      <td className="py-2 text-center pl-[90px]">$0.00</td>
                      <td className="py-2 grid justify-items-end pr-2">$3000.00</td>
                    </tr>
                    <tr className="border-b-2 text-base font-semibold border-bombay">
                      <td className="py-2 grid justify-items-start pl-2">Total</td>
                      <td className="py-2 text-center pl-[90px]">$0.00</td>
                      <td className="py-2 grid justify-items-end pr-2">$3000.00</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div className='pt-[72px]'>
              <h1 className='text-lg font-semibold pb-2'>Pre-tax Deductions</h1>
              <table className="w-full">
                  <tbody className="font-sans">
                    <tr className="border-b-2 border-bombay w-full">
                      <th className="text-sm font-semibold grid justify-items-start pl-2 py-2">DEDUCTION NAME</th>
                      <th className="text-sm font-semibold text-center py-2">EMPLOYER"S CONTRIBUTION</th>
                      <th className="text-sm font-semibold grid justify-items-end pr-2 py-2">EMPLOYEES' CONTRIBUTION</th>
                    </tr>
                    <tr className="text-sm font-normal">
                      <td className="py-2 grid justify-items-start pl-2">Deduction Name</td>
                      <td className="py-2 text-center pl-[90px]">$0.00</td>
                      <td className="py-2 grid justify-items-end pr-2">$3000.00</td>
                    </tr>
                    <tr className="border-b-2 text-base font-semibold border-bombay">
                      <td className="py-2 grid justify-items-start pl-2">Total</td>
                      <td className="py-2 text-center pl-[90px]">$0.00</td>
                      <td className="py-2 grid justify-items-end pr-2">$3000.00</td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
        </>
    </>
  )
}

export default TaxDeduction