import React from "react";
import Layout from "../../../Layout";
import { FaChevronDown } from "react-icons/fa";
import { useState } from "react";
import CustomDropdown from "../../../../components/custom-dropdown/CustomDropdown";
import AddVendorDialog from "../add-vendor/AddVendorDialog";
import AddVendor from "../add-vendor/AddVendor";
import VendorCredit from "../vendor-credit/VendorCredit";
import Dialog from "../../../../components/Dialog";

const VendorList = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogClose, setIsDialogClose] = useState(false);
  const [isVendorCreditDialogOpen, setIsVendorCreditDialogOpen] = useState(false);
  const [isVendorCreditDialogClose, setIsVendorCreditDialogClose] = useState(false);

  const handleSubmitVendor = () => {
    setIsDialogOpen((preventDefault) => !preventDefault);
  };
  const handleSubmitVendorCredit = () => {
    setIsVendorCreditDialogOpen((preventDefault) => !preventDefault);
  };
  return (
    <>
      <Layout>
        <div className="bg-white w-full h-full overflow-y-scroll px-8 font-sans">
          <div className="">
            <div className="w-full">
              <div className="w-full text-xl font-semibold font-sans my-10 justify-between flex">
                <div className="">
                  <h1>Vendors</h1>
                </div>
                <div className="float-right">
                  <button className="uppercase bg-lochmara text-sm font-sans font-semibold justify-center text-white rounded-[10px] w-[200px] h-[35px] flex py-2 pl-5"
                  onClick={handleSubmitVendor}>
                    New Vendor
                    <FaChevronDown className="content-center cursor-pointer mt-1 ml-8" />
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full flex gap-2">
              <div className="w-[25%]">
                <div className="font-sans font-normal text-base text-greyText">
                  <lable>Unpaid last 365 days</lable>
                </div>
                <div className="bg-lightblue h-[80px] rounded-sm py-3 px-7">
                  <div className="text-white  font-sans font-semibold">
                    <p className="text-2xl">$0</p>
                    <p className="text-lg">0 OVERDUE</p>
                  </div>
                </div>
              </div>
              <div className="bg-pearl w-[25%] h-[80px] rounded-sm mt-6 py-3 px-7">
                <div className="text-white  font-sans font-semibold">
                  <p className="text-2xl">$30.00</p>
                  <p className="text-lg">1 OPEN BILL</p>
                </div>
              </div>
              <div className="w-[50%]">
                <div className="font-sans font-normal text-base text-greyText">
                  <lable>Paid</lable>
                </div>
                <div className="bg-malachite w-full h-[80px] rounded-sm py-3 px-7">
                  <div className="text-white  font-sans font-semibold">
                    <p className="text-2xl">$50.00</p>
                    <p className="text-lg">1 PAID LAST 30 DAYS</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-10">
              <div className="justify-between flex">
                <div className="flex row gap-1 py-3 w-full">
                  <div className="">
                    <div className="text-sm font-medium">
                      <lable>Search</lable>
                    </div>
                    <div className="text-sm font-normal">
                      <div className="relative">
                        <input
                          type="search"
                          placeholder="Search"
                          className="w-[250px] h-[36px] py-1 pl-2  border border-bombay rounded-md text-gray-800 focus:outline-none focus:ring-1 focus:ring-lochmara"
                        />
                        <button className="absolute inset-y-0 right-0 flex items-center px-2">
                          <svg
                            width="24"
                            height="24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-bombay hover:text-blue-500"
                          >
                            <path
                              d="M23 21l-5.875-5.875a8.5 8.5 0 1 0-1.25 1.25L21 21l2 2zM10 16a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex content-center py-5 px-5">
                  <div className="w-6 h-6">
                    <img src="blob.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="icon8.png" />
                  </div>
                  <div className="w-6 h-6">
                    <img src="printer.jpg" />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <table className="w-full">
                  <tbody className="text-sm">
                    <tr className="border-b-2 border-bombay text-left uppercase">
                      <th className="w-[4%] py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </th>
                      <th className="w-[10%]">
                        <div className="flex">Vendor</div>
                      </th>
                      <th className="w-[18%]">Company Name</th>
                      <th className="w-[15%]">Phone</th>
                      <th className="w-[10%]">Email</th>
                      <th className="w-[15%] text-right">Open balance</th>
                      <th className="w-[20%] text-right">Action</th>
                    </tr>
                    <tr className="border-b-2 border-bombay">
                      <td className="py-2">
                        <input type="checkbox" className="w-5 h-5 mt-1" />
                      </td>
                      <td className="py-2">Jon Smith</td>
                      <td className="py-2">Sample Company Inc</td>
                      <td className="py-2">+1 999 999 9999</td>
                      <td className="py-2">test@email.com</td>
                      <td className="py-2 text-right">$501.25</td>
                      <td className="py-2 flex cursor-pointer justify-end text-nowrap pl-3">
                        <button  onClick={handleSubmitVendorCredit}>
                        <a className="text-royalBlue font-semibold pt-2">
                          Make payment |
                        </a>
                        </button>
                        <div className="">
                          <CustomDropdown
                            options={[
                              { value: "view", option: "View/Edit" },
                              {
                                value: "statement",
                                option: "Create statement",
                              },
                              { value: "invoice", option: "Create invoice" },
                              {
                                value: "receipt",
                                option: "Create sales receipt",
                              },
                              { value: "inactive", option: "Make inactive" },
                            ]}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
       
      </Layout>
      <AddVendorDialog isOpen={isDialogOpen} onClose={isDialogClose}>
        <AddVendor
          onSubmit={handleSubmitVendor}
          onClose={handleSubmitVendor}
        />
      </AddVendorDialog>
      <Dialog isOpen={isVendorCreditDialogOpen} onClose={isVendorCreditDialogClose} height='h-[750px]'>
        <VendorCredit
          onSubmit={handleSubmitVendorCredit}
          onClose={handleSubmitVendorCredit}
        />
      </Dialog>
    </>
  );
};

export default VendorList;
